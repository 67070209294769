import React, { useEffect, useState } from 'react';
// import React from 'react';

const SectionOne = () => {

    const [ourServiceSectionOne, setOurServiceSectionOne] = useState([]);
    const [imageOne, setImageOne] = useState('');
    const [imageTwo, setImageTwo] = useState('');
    const [imageThree, setImageThree] = useState('');

    const [fullName, setFullName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [service, setService] = useState('');
    const [message, setMessage] = useState('');

    const [csrfToken, setCsrfToken] = useState('');


    useEffect(() => {
        const fetchOurServiceSectionOne = async () => {
            try {
                // const result = await fetch('http://localhost:3001/api/fetch-our-service-section-one', {
                //     credentials: 'include', // Ensure cookies are sent with the request
                // });
                const result = await fetch('https://alshifamedicalsupplies.ae/api/fetch-our-service-section-one', {
                    credentials: 'include', // Ensure cookies are sent with the request
                });
                if (result.ok) {

                    // Get the CSRF token from the cookies or headers
                    const csrf = document.cookie.split('; ').find(row => row.startsWith('XSRF-TOKEN='))?.split('=')[1];
                    setCsrfToken(csrf); // This should correctly set your CSRF token
                    // console.log('csrf',csrf);
                    // return;
                    const data = await result.json();
                    console.log('data',data);
                    if (data && data.data && data.data.length > 0) {
                        const ourServicesectionOneData = data.data[0];
                        setOurServiceSectionOne(ourServicesectionOneData);
                        setImageOne(ourServicesectionOneData.imageOne.replace(/\\/g, '/'));
                        setImageTwo(ourServicesectionOneData.imageTwo.replace(/\\/g, '/'));
                        setImageThree(ourServicesectionOneData.imageThree.replace(/\\/g, '/'));
                        // console.log("setAboutSectionOne", data);
                    } else {
                        console.error('Failed to fetch ourServiceSectionOne: No valid data returned');
                    }
                } else {
                    console.error('Unexpected response structure:', result);
                }
            } catch (error) {
                console.error('Failed to fetch ourServiceSectionOne', error);
            }
        };

        fetchOurServiceSectionOne();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = {
            name: fullName,
            email: emailAddress,
            phone: phone,
            service: service,
            message: message,
        }

        console.log('csrfToken', csrfToken);
        // return;
        try {
            // await fetch('http://localhost:3001/api/our-service-section-one-form-create', {
            await fetch('https://alshifamedicalsupplies.ae/api/our-service-section-one-form-create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                },
                credentials: 'include', // Include cookies in the request
                body: JSON.stringify(data), // Ensure FormData is correctly passed
            });
        } catch (error) {
            console.error('Failed to save Our Service Section One Form Section One', error);
        }
    };

    return (
        <div className="services-section-one-content">


            <div class="blog_section">
                <div class="container">
                    <div class="row">

                        <div class="col-lg-7 col-md-8 col-sm-7 col-xs-12">

                            <div style={{ paddingBottom: '30px' }}>
                                <p className="ourServiceSectionOne-title-mobile-font" style={{ fontSize: '40px' }}>{ourServiceSectionOne.title}</p>
                            </div>

                            <article class="blog-post-wrapper clearfix">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="post-thumbnail">
                                            <img src={`https://alshifamedicalsupplies.ae/${imageOne}`} class="img-responsive " alt="imageOne" />
                                            {/* <img src={`http://localhost:3001/${imageOne}`} class="img-responsive " alt="imageOne" /> */}
                                            {/* <img src="images/blog/blog-single-1/blog-single-1.jpg" class="img-responsive " alt="Image" /> */}
                                        </div>

                                        <div class="service-blog-content">
                                            <div class="entry-content">
                                                {/* <p> Al Shifa Medical Supplies is one of the leading medical equipment suppliers in the UAE, offering a wide range of healthcare-related products to cater to every sector of the healthcare industry. In today’s fast-paced healthcare environment, it is essential to optimize budgetary expenditures and redistribute resources to meet the growing demand for high-quality medical care. Acquiring substandard or outdated medical equipment can negatively impact the standard of care and hinder competitiveness in the global healthcare landscape. Al Shifa Medical Supplies aims to simplify the lives of medical professionals by providing them with top-tier, reliable equipment that enables clinics and hospitals to offer comprehensive and effective treatments to their patients. </p> </div> */}
                                                <p> {ourServiceSectionOne.description} </p> </div>
                                        </div>
                                    </div>

                                    {/* <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                        <div class="service-blog-content">
                                            <div class="entry-content">
                                                <p>In recent years, the demand for medical devices has surged, along with the rising costs of these essential components. It is critical to invest in the best quality medical equipment that supports medical professionals in their daily tasks. Choosing the right supplier is crucial, and several factors should be considered when selecting medical equipment providers in Dubai. First and foremost, ensure that the supplier is ISO-certified, guaranteeing that the equipment has undergone rigorous testing by expert technicians to meet the highest quality standards. A reliable medical equipment supplier will also offer warranties for their devices, providing peace of mind regarding the longevity and durability of the equipment.</p>
                                                <p>A responsible medical equipment supplier in Dubai should maintain a comprehensive inventory and ensure timely orders when stock levels run low. This minimizes disruptions and ensures the consistent availability of essential medical devices. Furthermore, a reputable supplier like Al Shifa Medical Supplies prioritizes the safety and security of the equipment during transportation and delivery, preventing any harmful situations and boosting customer confidence in our products.</p>
                                                <p>At Al Shifa Medical Supplies, we are committed to providing a diverse range of medical equipment that facilitates the detection, diagnosis, and treatment of health conditions using state-of-the-art technology. We supply medical devices for hospitals and clinics across Dubai, with offerings that include storage equipment, transport equipment, durable medical equipment, electronic devices, surgical tools, acute care equipment, and procedural medical instruments.</p>
                                                <p>Our extensive experience and professionalism have positioned Al Shifa Medical Supplies as a trusted name in the UAE’s medical equipment supply industry. We strive to deliver the highest quality products from leading brands and continuously enhance the customer experience. Our dedication to quality and service has earned us a reputation as one of the top medical equipment suppliers in the region.</p>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </article>

                        </div>

                        <div class="col-lg-5 col-md-4 col-sm-5 col-xs-12">

                            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">

                                <form>
                                    <div class="request-form comments_form">
                                        <h4>Request Form</h4>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="formsix-pos">
                                                    <div class="form-group i-name">
                                                        <label class="sr-only">Name</label>
                                                        <input type="text" class="form-control border-color" id="fullName" placeholder="Full Name*" required onChange={(e) => setFullName(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="formsix-e">
                                                    <div class="form-group i-email">
                                                        <label class="sr-only">Email</label>
                                                        <input type="email" class="form-control border-color" id="emailAddress" placeholder="Email Address*" onChange={(e) => setEmailAddress(e.target.value)} required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="formsix-e">
                                                    <div class="form-group i-phone">
                                                        <label class="sr-only">Phone</label>
                                                        <input type="phone" class="form-control border-color" id="phone" placeholder="Phone*" onChange={(e) => setPhone(e.target.value)} required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="formsix-e">
                                                    <div class="form-group">
                                                        <label class="sr-only">Select Service</label>
                                                        <select class="form-control select2 border-color" name="services" id="services" onChange={(e) => setService(e.target.value)} required>
                                                            <option selected>Select an Option</option>
                                                            <option value="Medical Equipment Supply">Medical Equipment Supply</option>
                                                            <option value="Clinic Training For Equipment">Clinic Training For Equipment</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="formsix-m">
                                                    <div class="form-group i-message">
                                                        <label class="sr-only">Message</label>
                                                        <textarea class="form-control border-color" rows="7" id="messageTen" placeholder="Message" onChange={(e) => setMessage(e.target.value)} required></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-center mt-3">
                                            <button type="submit" class="btn btn-primary" onClick={handleSubmit}>Send Request</button>
                                        </div>
                                    </div>
                                </form>

                            </div>

                        </div>

                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                    <div class="post-thumbnail">
                                        <img src={`https://alshifamedicalsupplies.ae/${imageTwo}`} alt="imageTwo" />
                                        {/* <img src={`http://localhost:3001/${imageTwo}`} alt="imageTwo" /> */}
                                        {/* <img src="images/blog/blog-single-1/blog-single-2.jpg" alt="Image" /> */}
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                                    <div class="post-thumbnail">
                                        <img src={`https://alshifamedicalsupplies.ae/${imageThree}`} alt="imageThree" />
                                        {/* <img src={`http://localhost:3001/${imageThree}`} alt="imageThree" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default SectionOne;
