// import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/home-page/Home';
import About from './pages/about-page/About';
import Services from './pages/services-page/Services';
import Header from '../src/components/Header';
import Footer from "../src/components/Footer";
// import adminLayout from './components/admin-panel/adminPanelLayout';
// import UserList from './pages/adminpanel/user/user-list';
// import AddUser from './pages/adminpanel/user/add-user';
// import logo from './logo.svg';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "./styles.css";


function App() {


  return (
    // <div className="App">
    //   <header className="App-header">

    //   </header>
    // </div>
    <Router>
      <Header />
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
        </Routes>
      </Layout>
      <Footer />


      {/* <adminLayout>
        <Routes>
          <Route path="/user-list" element={<AddUser />} />
          <Route path="/add-user" element={<UserList />} />
        </Routes>
      </adminLayout> */}

    </Router>
  );
}

export default App;
