import React, { useEffect, useState } from 'react';

const SectionTwo = () => {

    const [homeSectionTwo, setHomeSectionTwo] = useState([]);

    useEffect(() => {
        const fetchHomeSectionTwo = async () => {
            try {
                // const result = await fetch('http://localhost:3001/api/fetch-home-section-two');
                const result = await fetch('https://alshifamedicalsupplies.ae/api/fetch-home-section-two');
                if (result.ok) {
                    const data = await result.json();
                    if (data && data.data && data.data.length > 0) {
                        setHomeSectionTwo(data.data[0]);
                        // console.log("Home Section Two rendered", data);
                    } else {
                        console.error('Failed to fetch homeSectionTwo: No valid data returned');
                    }
                } else {
                    console.error('Unexpected response structure:', result);
                }
            } catch (error) {
                console.error('Failed to fetch homeSectionTwo', error);
            }
        };

        fetchHomeSectionTwo();
    }, []);

    return (
        <div className="section-two-content">

            <div className="services_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-12 col-sm-6">
                            <div className="icon_text" style={{ height: '333px' }}>
                                <div className="icon_text_effect">
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                </div>
                                <h4 className="text-center">{ homeSectionTwo.cardOneTitle }</h4>
                                <p className="text-center">{ homeSectionTwo.cardOneDescription }</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-xs-12 col-sm-6">
                            <div className="icon_text" style={{ height: '333px' }}>
                                <div className="icon_text_effect">
                                    <i className="fa fa-lightbulb-o" aria-hidden="true"></i>
                                </div>
                                <h4 className="text-center">{ homeSectionTwo.cardTwoTitle }</h4>
                                <p className="text-center">{ homeSectionTwo.cardTwoDescription }</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-xs-12 col-sm-6">
                            <div className="icon_text" style={{ height: '333px' }}>
                                <div className="icon_text_effect">
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                </div>
                                <h4 className="text-center">{ homeSectionTwo.cardThreeTitle }</h4>
                                <p className="text-center">{ homeSectionTwo.cardThreeDescription }</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-xs-12 col-sm-6">
                            <div className="icon_text" style={{ height: '333px' }}>
                                <div className="icon_text_effect">
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                </div>
                                <h4 className="text-center">{ homeSectionTwo.cardFourTitle }</h4>
                                <p className="text-center">{ homeSectionTwo.cardFourDescription }</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}

export default SectionTwo;
