import React, { useEffect, useState } from 'react';
// import React from 'react';

const SectionTwo = () => {

    const [aboutSectionTwo, setAboutSectionTwo] = useState([]);
    const [bgImage, setBgImage] = useState('');
    const [imageOne, setImageOne] = useState('');
    const [imageTwo, setImageTwo] = useState('');

    useEffect(() => {
        const fetchAboutSectionTwo = async () => {
            try {
                // const result = await fetch('http://localhost:3001/api/fetch-about-section-two');
                const result = await fetch('https://alshifamedicalsupplies.ae/api/fetch-about-section-two');
                if (result.ok) {
                    const data = await result.json();
                    if (data && data.data && data.data.length > 0) {
                        const sectionData = data.data[0];
                        setAboutSectionTwo(sectionData);
                        setBgImage(sectionData.bgImage.replace(/\\/g, '/'));
                        setImageOne(sectionData.imageOne.replace(/\\/g, '/'));
                        setImageTwo(sectionData.imageTwo.replace(/\\/g, '/'));
                        // console.log('aboutSectionTwo bgImage',bgImage);
                        // console.log("setAboutSectionTwo", data.data[0]);
                    } else {
                        console.error('Failed to fetch aboutSectionTwo: No valid data returned');
                    }
                } else {
                    console.error('Unexpected response structure:', result);
                }
            } catch (error) {
                console.error('Failed to fetch aboutSectionTwo', error);
            }
        };

        fetchAboutSectionTwo();
    }, []);



    return (
        <div className="about-section-two-content">

            <div class="testimonial_wrapper"
                style={{
                    backgroundImage: `url(https://alshifamedicalsupplies.ae/${bgImage})`,
                    // backgroundImage: `url(http://localhost:3001/${bgImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}>

                <div class="container">
                    <div class="row">
                        <div class="col-sm-6 col-12">
                            <div class="thumbnail clearfix">
                                {/* <img class="img-circle" src={`http://localhost:3001/${imageOne}`} alt="img" style={{ height: '100px', width: '100px', borderRadius: '20%' }} /> */}
                                <img class="img-circle" src={`http://alshifamedicalsupplies.ae/${imageOne}`} alt="img" style={{ height: '100px', width: '100px', borderRadius: '20%' }} />
                                <div class="caption">
                                    <h4>{aboutSectionTwo.title_one}</h4>
                                </div>
                                <div class="caption">
                                    <p style={{ color: 'black', fontFamily: 'Roboto', fontSize: '13px' }}>{aboutSectionTwo.description_one}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-12">
                            <div class="thumbnail clearfix">
                                {/* <img class="img-circle" src={`http://localhost:3001/${imageTwo}`} alt="img" style={{ height: '100px', width: '100px', borderRadius: '20%' }} /> */}
                                <img class="img-circle" src={`http://alshifamedicalsupplies.ae/${imageTwo}`} alt="img" style={{ height: '100px', width: '100px', borderRadius: '20%' }} />
                                <div class="caption">
                                    <h4>{aboutSectionTwo.title_two}</h4>
                                </div>
                                <div class="caption">
                                    <p style={{ color: 'black', fontFamily: 'Roboto', fontSize: '13px' }}>{aboutSectionTwo.description_two}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
}

export default SectionTwo;
