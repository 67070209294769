import React from "react";
import Header from "./sections/header";
import SectionOne from "./sections/section-one";
import SectionTwo from "./sections/section-two";

const About = () => {

    return (
        <div className="content">

            <Header/>
            <SectionOne/>
            <SectionTwo/>

        </div>
    );
}

export default About;