import React, { useEffect, useState } from 'react';

const SectionOne = () => {

    const [aboutSectionOne, setAboutSectionOne] = useState([]);

    useEffect(() => {
        const fetchAboutSectionOne = async () => {
            try {
                // const result = await fetch('http://localhost:3001/api/fetch-about-section-one');
                const result = await fetch('https://alshifamedicalsupplies.ae/api/fetch-about-section-one');
                if (result.ok) {
                    const data = await result.json();
                    if (data && data.data && data.data.length > 0) {
                        setAboutSectionOne(data.data[0]);
                        // console.log("setAboutSectionOne", data);
                    } else {
                        console.error('Failed to fetch aboutSectionOne: No valid data returned');
                    }
                } else {
                    console.error('Unexpected response structure:', result);
                }
            } catch (error) {
                console.error('Failed to fetch aboutSectionOne', error);
            }
        };

        fetchAboutSectionOne();
    }, []);



    return (
        <div className="about-section-one-content">

            <div class="section_1">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                            <div class="about_text_wrapper">
                                {/* <h2>Hello ! About Our Agency</h2> */}
                                <h2>{aboutSectionOne.title}</h2>
                                {/* <p>This is Photoshop's version of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. aks Aenean sollicitudin, lorem quis bibendum auctor.</p> */}
                                <p>{aboutSectionOne.description}</p>
                                {/* <h3>Website Builder UI Kit</h3>
                                <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit aks amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec us a odio tincidunt auctor a ornare odio.</p> */}
                                {/* <button class="btn_discover" type="button">Discover</button>
                                <button class="btn_read_more" type="button">read more</button> */}
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                            <div class="accordion_wrapper">
                                <div class="panel-group" id="accordionOneLeft">

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordionOneLeft" href="#collapseLeftone" aria-expanded="true">
                                                {aboutSectionOne.title_one}
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseLeftone" class="panel-collapse collapse in" aria-expanded="false" role="tablist">
                                            <div class="panel-body">
                                                <div class="img-accordion">
                                                    <img src="images/about_us/accordion1.jpg" alt="" />
                                                </div>
                                                <div class="text-accordion">
                                                    <p>
                                                    {aboutSectionOne.description_one}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionOneLeft" href="#collapseLeftTwo" aria-expanded="false">
                                                {aboutSectionOne.title_two}
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseLeftTwo" class="panel-collapse collapse" aria-expanded="false" role="tablist">
                                            <div class="panel-body">
                                                <div class="img-accordion">
                                                    <img src="images/about_us/accordion2.jpg" alt="" />
                                                </div>
                                                <div class="text-accordion">
                                                    <p>
                                                    {aboutSectionOne.description_two}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionOneLeft" href="#collapseLeftThree" aria-expanded="false">
                                                {aboutSectionOne.title_three}
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseLeftThree" class="panel-collapse collapse" aria-expanded="false" role="tablist">
                                            <div class="panel-body">
                                                <div class="img-accordion">
                                                    <img src="images/about_us/accordion3.jpg" alt="" />
                                                </div>
                                                <div class="text-accordion">
                                                    <p>
                                                    {aboutSectionOne.description_three}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionOneLeft" href="#collapseLeftFour" aria-expanded="false">
                                                {aboutSectionOne.title_four}

                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseLeftFour" class="panel-collapse collapse" aria-expanded="false" role="tablist">
                                            <div class="panel-body">
                                                <div class="img-accordion">
                                                    <img src="images/about_us/accordion3.jpg" alt="" />
                                                </div>
                                                <div class="text-accordion">
                                                    <p>
                                                    {aboutSectionOne.description_four}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    );
}

export default SectionOne;
