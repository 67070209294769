import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { Navigation } from 'swiper/modules';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
// import 'swiper/swiper-bundle.css';
import 'swiper/css';


const SectionFour = () => {

    const [homeSectionFourParent, setHomeSectionFourParent] = useState({});
    const [homeSectionFourChild, setHomeSectionFourChild] = useState([]);

    useEffect(() => {
        const fetchHomeSectionFour = async () => {
            try {
                // const result = await fetch('http://localhost:3001/api/fetch-home-section-four');
                const result = await fetch('https://alshifamedicalsupplies.ae/api/fetch-home-section-four');
                if (result.ok) {
                    const data = await result.json();
                    if (data && data.data && data.data.home_sec_four && data.data.home_sec_four.length > 0) {
                        setHomeSectionFourParent(data.data.home_sec_four[0]);
                    }
                    if (data && data.data && data.data.home_sec_four_child && data.data.home_sec_four_child.length > 0) {
                        setHomeSectionFourChild(data.data.home_sec_four_child);
                    }
                } else {
                    console.error('Unexpected response structure:', result);
                }
            } catch (error) {
                console.error('Failed to fetch homeSectionFour', error);
            }
        };
        // console.log('homeSectionFourChild', homeSectionFourChild);
        fetchHomeSectionFour();
    }, []);

    // const slidesPerView = homeSectionFourChild.length >= 3 ? 3 : homeSectionFourChild.length;

    return (
        <div className="section-four-content">

            <div class="featured_products_section" style={{ marginTop: '50px' }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 col-md-12 col-xs-12 col-sm-12 col-lg-offset-2">
                            <div class="section_heading">
                                {/* <h2>Featured Products</h2> */}
                                <h2>{homeSectionFourParent.title}</h2>
                                <span class="bordered-icon"> <i class="fa fa-square"></i> <i class="fa fa-square"></i> <i class="fa fa-square"></i> </span>
                            </div>
                            <div class="section_content">
                                {/* <p>Discover our top-rated featured products, known for quality and innovation.</p> */}
                                <p>{homeSectionFourParent.description}</p>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ zIndex: 0 }}>
                            {/* <div class="featured_products_slider"> */}
                            {/* <div class="owl-carousel owl-theme"> */}

                            <Swiper
                                modules={[Navigation, Autoplay]}
                                spaceBetween={30}
                                slidesPerView={4}
                                autoplay={{ delay: 3000 }}
                                navigation={true}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log(swiper)}
                            >
                                {homeSectionFourChild.map((child, index) => (
                                    <SwiperSlide key={index}>

                                        <div class="shop_cont_box_wrapper">
                                            <div class="shop_img_wrapper">

                                                {/* <img  src="images/home/home-6/product-1.jpg" alt="shop_img" class="img-responsive" style={{ width: '263px', height: '317px' }}/> */}
                                                <img src={`https://alshifamedicalsupplies.ae/uploads/${child.product_image}`} alt="shop_img" class="img-responsive" style={{ width: '263px', height: '317px' }} />
                                                <div class="featured_products_overlay">
                                                    <div class="featured_products_img_text">
                                                        <ul>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="shop_img_cont_wrapper">
                                                <h1>{child.product_title}</h1>
                                                <div class="featured_products_cont_wrapper" style={{ marginTop: '20px' }}>
                                                    <p style={{ fontWeight: 500 }}>{child.product_description}</p>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star-o"></i>
                                                    <i class="fa fa-star-o"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            {/* </div> */}

                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}

export default SectionFour;
