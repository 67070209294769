import React, { useEffect, useState } from 'react';

const SectionThree = () => {

    const [homeSectionThree, setHomeSectionThree] = useState([]);

    useEffect(() => {
        const fetchHomeSectionThree = async () => {
            try {
                // const result = await fetch('http://localhost:3001/api/fetch-home-section-three');
                const result = await fetch('https://alshifamedicalsupplies.ae/api/fetch-home-section-three');
                if (result.ok) {
                    const data = await result.json();
                    if (data && data.data && data.data.length > 0) {
                        setHomeSectionThree(data.data[0]);
                    } else {
                        console.error('Failed to fetch homeSectionThree: No valid data returned');
                    }
                } else {
                    console.error('Unexpected response structure:', result);
                }
            } catch (error) {
                console.error('Failed to fetch homeSectionThree', error);
            }
        };

        fetchHomeSectionThree();
    }, []);

    return (
        <div className="section-three-content">

            {/* <div class="features_list_wrapper"> */}
            <div class="features_list_wrapper" style={{
                backgroundImage: `url(https://alshifamedicalsupplies.ae/${homeSectionThree.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}>
                <div class="features_list_overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 col-md-12 col-xs-12 col-sm-12 col-lg-offset-2">
                            <div class="section_heading">
                                <h2>{homeSectionThree.title}</h2>
                                <p>{homeSectionThree.description}</p>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-xs-12 col-sm-6">
                                    <div class="text_wrapper_left">
                                        <div class="icon_wrapper_list">
                                            <div class="icon_content_left">
                                                <h4 style={{ color: 'white' }}>{homeSectionThree.title_one}</h4>
                                                <p>{homeSectionThree.description_one}</p>
                                            </div>
                                            <div class="icon_img_effect">
                                                <div class="icon_img_list">
                                                    <i class="fa fa-cloud-download" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="icon_wrapper_list">
                                            <div class="icon_content_left">
                                                <h4 style={{ color: 'white' }}>{homeSectionThree.title_two}</h4>
                                                <p>{homeSectionThree.description_two}</p>
                                            </div>
                                            <div class="icon_img_effect">
                                                <div class="icon_img_list">
                                                    <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="icon_wrapper_list">
                                            <div class="icon_content_left">
                                                <h4 style={{ color: 'white' }}>{homeSectionThree.title_three}</h4>
                                                <p>{homeSectionThree.description_three}</p>
                                            </div>
                                            <div class="icon_img_effect">
                                                <div class="icon_img_list">
                                                    <i class="fa fa-life-ring" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-xs-12 hidden-sm hidden-xs" style={{ position:'relative', marginTop:'150px' }}>
                                    <div class="features_list_img_wrapper">
                                        <img class="img-responsive" src={`https://alshifamedicalsupplies.ae/${homeSectionThree.image}`}
                                            alt="section-2-img" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-xs-12 col-sm-6">
                                    <div class="text_wrapper_right">
                                        <div class="icon_wrapper_list">
                                            <div class="icon_img_effect">
                                                <div class="icon_img_list">
                                                    <i class="fa fa-desktop" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div class="icon_content_right">
                                                <h4 style={{ color: 'white' }}>{homeSectionThree.title_four}</h4>
                                                <p>{homeSectionThree.description_four}</p>
                                            </div>
                                        </div>
                                        <div class="icon_wrapper_list">
                                            <div class="icon_img_effect">
                                                <div class="icon_img_list">
                                                    <i class="fa fa-tint" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div class="icon_content_right">
                                                <h4 style={{ color: 'white' }}>{homeSectionThree.title_five}</h4>
                                                <p>{homeSectionThree.description_five}</p>
                                            </div>
                                        </div>
                                        <div class="icon_wrapper_list">
                                            <div class="icon_img_effect">
                                                <div class="icon_img_list">
                                                    <i class="fa fa-shield" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div class="icon_content_right">
                                                <h4 style={{ color: 'white' }}>{homeSectionThree.title_six}</h4>
                                                <p>{homeSectionThree.description_six}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 visible-sm visible-xs">
                                    <div class="features_list_img_wrapper">
                                        <img class="img-responsive" src={`https://alshifamedicalsupplies.ae/${homeSectionThree.image}`}
                                            alt="section-2-img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}

export default SectionThree;
