import React from "react";
import Banner from "./sections/banner";
import SectionTwo from "./sections/section-two";
import SectionThree from "./sections/section-three";
import SectionFour from "./sections/section-four";

const Home = () => {

    return (
        <div className="content">

            <Banner/>
            <SectionTwo/>
            <SectionThree/>
            <SectionFour/>

        </div>
    );
}

export default Home;